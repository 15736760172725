<template>
	<div>
		<div class="content">
			<!-- 补单 -->
			<div class="mb20 pageTitle"><b>补增订单</b></div>
			<div class="tips cdanger mb20 textCenter">系统无订单，在此补单</div>
			<div>
				<el-form class="form" ref="form" :model="form" label-width="120px" v-loading='loading'>
					<el-form-item label="商家号:">
						<el-input v-model="business_no" placeholder="请输入商家号"></el-input>
					</el-form-item>
					<el-form-item label="商家名称">
						<el-select v-model="form.business_no" placeholder="请选择商家" style="display: block;" value-ley="id" @change="chooseTra">
							<el-option v-for="item in traList" :key="item.business_no" :label="item.business_name" :value="item.business_no"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="金额:">
						<el-input v-model="form.money" placeholder="请输入金额"></el-input>
					</el-form-item>
					<el-form-item label="支付方式:">
						<el-select v-model="form.payType" placeholder="请选择支付方式" style="display: block;"  @change="choosePayType">
							<el-option v-for="item in payList" :key="item.id" :label="item.name" :value="item.id"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="账号:">
						<el-select v-model="form.alipay_id" placeholder="请选择账号" style="display: block;" filterable>
							<el-option v-for="item in cardDataList" v-if="item.id" :key="item.id" :label="item.name" :value="item.id"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="备注:">
						<el-input type="textarea" :rows="2" autosize v-model="form.note" placeholder="请输入备注"></el-input>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="onSubmit">确定</el-button>
					</el-form-item>
				</el-form>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		isEmpty,
		isPositiveNumber,
		isMoney
	} from '@/util/validate'
	import {
		Message
	} from 'element-ui'
	export default {
		data() {
			return {
				traList: [],
				payList: [],
				cardDataList: [],
				business_no: '',
				loading:false,
				form: {
					business_no: '',
					money: '',
					payType: '',
					alipay_id: '',
					note:'',
				}
			}
		},
		created() {
			this.traderList()
			this.paywayAll()
		},
		methods: {
			onSubmit() {
				if(isEmpty(this.form.business_no,'请选择商家')){
					return
				}
				if(isEmpty(this.form.money,'请输入金额')){
					return
				}
				if(!isMoney(this.form.money,'金额只能是数字，且最多保留两位小数')){
					return
				}
				if(isEmpty(this.form.payType,'请选择支付方式')){
					return
				}
				if(isEmpty(this.form.alipay_id,'请选择账号')){
					return
				}
				let data = this.form
				
				this.loading=true
				this.$api.addOrderAlipayOrNumber(data).then(res=>{
					this.loading=false
					if(res.status == 1){
						this.$message({type:'success',message:'补增订单成功'})
						 this.form = {
							 business_no: '',
							 money: '',
							 payType: '',
							 alipay_id: ''
						}
						this.business_no=''
						this.$alert('补增订单编号为:' + res.data, '提示', {
							confirmButtonText: '确定',
						});
					}else{
						this.$message({type:'error',message:res.msg})
					}
				}).catch(error=>{
					this.loading=false
				})
			},
			traderList() {
				let data = {
					size: 10000,
					page: 1
				}
				this.$api.traderList(data).then(res => {
					if (res.status == 1) {
						this.traList = res.data.data
					}
				})
			},
			paywayAll() {
				let data = {
					size: 10000,
					page: 1,
					hasRepay: 2, //可用通道
				}
				this.$api.paywayAll(data).then(res => {
					if (res.status == 1) {
						this.payList = res.data.data
					}
				})
			},
			chooseTra(val) {
				this.business_no = val
				this.form.payType = ''
				this.form.alipay_id = ''
				// this.cardList(val)
			},
			choosePayType(val) {
				this.form.alipay_id = ''
				this.cardList()
			},
			cardList() {
				// let type = ''
				// if(this.form.payType == 40){
				// 	type = 2
				// }
				// if(this.form.payType == 98 || this.form.payType == 99){
				// 	type = 1
				// }
				let data = {
					size: 10000,
					page: 1,
					// business_no: this.business_no,
					// type: type,
					pay_type: this.form.payType
				}
				this.$api.alipayall(data).then(res => {
					if (res.status == 1) {
						let cardList = res.data.data.map(item => {
							return {
								name: item.all_parent_name + ' | ' + item.true_name + '-' + item.account,
								id: item.id
							}
						})
						this.cardDataList = cardList
					}
				})
			},
			
		},
		watch: {
			// 'business_no': function(val, oldval) {
			// 	let hasBusi = false;
			// 	for (let i = 0; i < this.traList.length; i++) {
			// 		if (val == this.traList[i].business_no) {
			// 			this.form.business_no = val;
			// 			hasBusi = true;
			// 			this.cardList(val)
			// 			break;
			// 		}
			// 	}
			// 	if(!hasBusi){
			// 		this.form.business_no=''
			// 	}
			// }, //键路径必须加上引号
		},
	}
</script>

<style lang="scss" scoped="scoped">
	@import '@/style/variables.scss';

	.content {
		.pageTitle {
			background-color: $navSubColor;
			padding: 10px;
		}

		.tips {
			width: 460px;
		}

		.form {
			width: 460px;
		}
	}
</style>
